import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import SEO from '../components/SEO'

export default class Blog extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <Layout>
        <SEO
          isBlogPost={false}
          postData={{
            title: 'Blog de Chicas al Frente',
            description: 'Te invitamos a conocer más sobre el movimiento feminista en Costa Rica y de la región'
          }}
          postImage={posts && posts[0].node.fields.cover_image_rel ? posts[0].node.fields.cover_image_rel.childImageSharp.fluid.src : ''}
          slug="/blog"
        />
        <PostList
          posts={posts}
          title="Últimas Entradas"
          pageContext={pageContext} />
      </Layout>
    )
  }
}

Blog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC},
      skip: $skip, limit: $limit,
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
            cover_image_rel {
              childImageSharp {
                fluid(maxWidth: 2200, maxHeight: 800) {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                }
              }
            }
          }
          frontmatter {
            title
            templateKey            
            author {
              name
            }
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
